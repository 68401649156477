.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-title{
  /* font-family: Montserrat; */
  font-family: 'Chakra Petch', sans-serif;
  font-weight: 800;
  font-size: 50px;
  line-height: 1.25;
margin-bottom: 10px;
  color: #191847;
}

.pricing-title h2{
  font-family: 'Chakra Petch', sans-serif;
  font-weight: 800;
  font-size: 50px;
  line-height: 1.25;
  margin-bottom: 10px;
  color: #191847;
}

.pricing-title p{
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 25px;
}

.tutors-section{
  padding: 0px 0 0px 0;
}